
export const UsuarioEntity = {
    id: null,
    username: '',
    password: '',

    nombre: '',
    apellido: '',

    docIdentidad: '',
    escalafon:'',
    
    email: '',
    nroTelefono: '',

    regimen: '',
    ultSesion: '',
    estado: null,
    role: ''
};
