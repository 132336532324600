import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Cookies from 'universal-cookie';
import axios from 'axios';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';

import { environment } from "./util/baseUrl.js";

function Login() {
    const baseUrl = environment.baseUrl + "auth/signin";
    const cookies = new Cookies();
    let navigate = useNavigate();
    const toast = useRef(null);
    const [form, setForm] = useState({
        username: '',
        password: ''
    });
    const handleChange = e => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    }

    const iniciarSesion = async () => {
        await axios.post(baseUrl, form)
            .then(response => {
                if (response.data.token != null) {
                    var respuesta = response.data;
                    cookies.set('token', respuesta.token, { path: '/' });
                    cookies.set('id', respuesta.id, { path: '/' });
                    cookies.set('username', respuesta.username, { path: '/' });
                    cookies.set('nombre', respuesta.nombre, { path: '/' });
                    cookies.set('apellido', respuesta.apellido, { path: '/' });
                    cookies.set('docIdentidad', respuesta.docIdentidad, { path: '/' });
                    cookies.set('escalafon', respuesta.escalafon, { path: '/' });
                    cookies.set('email', respuesta.email, { path: '/' });
                    cookies.set('nroTelefono', respuesta.nroTelefono, { path: '/' });
                    cookies.set('regimen', respuesta.regimen, { path: '/' });
                    cookies.set('ultSesion', respuesta.ultSesion, { path: '/' });
                    cookies.set('estado', respuesta.estado, { path: '/' });
                    cookies.set('role', respuesta.role, { path: '/' });
                    toast.current.show({ severity: 'success', summary: 'Logeado Correctamente', detail: 'Bienvenido ' + respuesta.nombre + ' ' + respuesta.apellido, life: 3000 });
                    setTimeout(() => {
                        navigate('/');
                    }, 1500);
                }
            })
            .catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Usuario o Contraseña incorrectos', life: 5000 });
            })
    }

    useEffect(() => {
        if (cookies.get('id')) {
            navigate('/');
        }
        document.documentElement.style.fontSize = 12 + 'px';
    }, []);


    const styles = {
        body: {
            backgroundImage: "url(/logos/fondo.png)",
            // color: 'white',
            height: '100vh',
            // width: '100vw',
            // display: 'flex',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        },
        component: {
            background: '#9B1B21',
            color: 'white',
        },
        texto: {
            color: '#9B1B21',
        },
        formulario: {
            border: 'thick solid #FFFFFF',
            opacity: '0.97',
        }

    }

    return (
        <>
            <Toast ref={toast} />
            <div style={styles.body}  className="surface-ground px-4 py-8 md:px-4 lg:px-8">
                <div className="grid">
                    {/* Columna izquierda: Formulario */}
                    <div className="col-12 lg:col-6 flex align-items-center justify-content-center">
                        <div className="col-12">
                            <div align="center">
                                <img src="/logos/bienvenidos.png" alt="bienvenidos" style={{ width: '100%', maxWidth: '450px'  }} className="mb-4" />
                            </div>
                            <br />
                            <div align="center">
                                <div style={styles.formulario} className="surface-card p-6 shadow-8 border-round w-full lg:w-8 mb-4">
                                    <div className="mb-2">
                                        <div className="p-inputgroup">
                                            <span style={styles.component} className="p-inputgroup-addon">
                                                <i className="pi pi-user"></i>
                                            </span>
                                            <InputText placeholder="Usuario" id="username" type="text" name="username" onChange={handleChange} />
                                        </div>
                                        <br />
                                        <div className="p-inputgroup mb-4">
                                            <span style={styles.component} className="p-inputgroup-addon">
                                                <i className="pi pi-lock"></i>
                                            </span>
                                            <InputText placeholder="Contraseña" id="password" type="password" name="password" onChange={handleChange} />
                                        </div>
                                        <br />

                                        <div style={styles.texto} className="flex align-items-center justify-content-between mb-6">
                                            <div className="flex align-items-center">
                                                <Checkbox id="rememberme" className="mr-2" />
                                                <label htmlFor="rememberme">Acuérdate de mí</label>
                                            </div>
                                            <label className="font-medium no-underline ml-2 text-right cursor-pointer">¿Olvidaste tu contraseña?</label>
                                        </div>

                                        <Button style={styles.component} label="Iniciar Sesión" icon="pi pi-user" className="w-full" onClick={() => iniciarSesion()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Columna derecha: Imagen */}
                    <div className="col-12 lg:col-6 flex justify-content-end">
                        <img src="/logos/img.png" alt="imagen" style={{ width: '100%', maxWidth: '860px', marginRight: '-60px', marginTop: '12px' }} className="mb-4" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;