import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { environment } from "./util/baseUrl";
import { UsuarioEntity } from '../entity/UsuarioEntity';

export const Usuario = () => {

    let empty = UsuarioEntity;

    const cookies = new Cookies();
    let navigate = useNavigate();

    const baseUrl = environment.baseUrl + "usuario/";
    const [data, setData] = useState(null);
    const [EntidadNewDialog, setEntidadNewDialog] = useState(false);
    const [reniec, setReniec] = useState(null);

    const [product, setProduct] = useState(empty);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [mod, setModulo] = useState('');
    const toast = useRef(null);
    const dt = useRef(null);

    // Inicia valores para modificar Regimen 
    const [regimenId, setRegimenId] = useState(null);
    const optionsRegimen = [
        { label: '728', value: '728' },
        { label: '276', value: '276', },
        { label: '1057', value: '1057', }
    ]
    
    const handleChangeRegimen = (event) => {
        setRegimenId(event.value);
        product.regimen = event.value;
    };
    // Cierra valores para modificar Regimen 


    const peticionGet = async () => {
        await axios.get(baseUrl)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionPost = async () => {
        delete product.id;
        await axios.post(baseUrl, product)
            .then(response => {
                setData(data.concat(response.data));
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const peticionPut = async () => {
        try {
            await axios.put(baseUrl, product)
                .then(() => {
                    var dataNueva = data.map(u => {
                        if (u.id === product.id) {
                            u.username = product.username;
                            u.nombre = product.nombre;
                            u.apellido = product.apellido;
                            u.regimen = product.regimen;
                            u.nroTelefono = product.nroTelefono;
                            u.email = product.email;
                            u.escalafon = product.escalafon;
                        }
                        return u;
                    });
                    setData(dataNueva);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Modificado', life: 3000 });
                })
                .catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
                });
        } catch (error) {
            console.error(error);
        }
    };

    const peticionPutReiniciarPass = async (product) => {
        await axios.post(baseUrl + 'passgenerico', product)
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const peticionRENIEC = async () => {
        await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpnYWl0YW5yQG91dGxvb2suY29tIn0.hfkwOQKGq46Czu7RvqI1iSwgN8UGK02UEF4S9ZqXaCQ")
            /*await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpnYWl0YW5yQHBqLmdvYi5wZSJ9.-nX87AiyjDvfW2SeGAhWFnx0MDCiB8meK06aAAlVfJQ")*/
            .then(response => {
                setReniec(response.data);
                console.log(response.data);
                const nombre = response.data.nombres;
                const apellido = response.data.apellidoPaterno + " " + response.data.apellidoMaterno; // Ajusta la ruta de acceso correcta a la propiedad "nombres" según la respuesta del API
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    nombre: nombre,
                    apellido: apellido,
                }));
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (cookies.get('role')==='USER') {
            navigate('/');
        }
        peticionGet();
    }, []);

    const BuscarDNI = () => {
        setSubmitted(true);
        if (product.docIdentidad.trim()) {
            peticionRENIEC();
            //setProduct(empty);
        }
    }

    const openNew = () => {
        setProduct(empty);
        setSubmitted(false);
        setEntidadNewDialog(true);
    }
    const hideDialogNew = () => {
        setSubmitted(false);
        setEntidadNewDialog(false);
    }
    const editProduct = (product) => {
        setProduct({ ...product });
        setEntidadNewDialog(true);
    }

    const restaurarPass = (product) => {
        setProduct({ ...product });
        peticionPutReiniciarPass(product);
    }

    const saveProduct = () => {
        setSubmitted(true);
        product.modulo = mod;
        if (product.docIdentidad.trim()) {
            let _products = [...data];
            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);
                _products[index] = _product;
                peticionPut();
            }
            else {
                peticionPost();
                _products.push(_product);
            }
            setEntidadNewDialog(false);
            setData(_products);
            setProduct(empty);
        }
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }



    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;
        setProduct(_product);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Registrar Usuario" icon="pi pi-user-plus" className="p-button-outlined p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-outlined p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.docIdentidad}
            </>
        );
    }

    const escalafonBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.escalafon}
            </>
        );
    }

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.nombre + ' ' + rowData.apellido}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.email}
            </>
        );
    }

    const telefonoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.nroTelefono}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button title="Modificar Usuario" icon="pi pi-pencil" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button title="Restaurar Contraseña" icon="pi pi-lock-open" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => restaurarPass(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Configuración de Usuarios</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooterNew = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogNew} />
            <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Ver {first} a {last} de {totalRecords} Usuarios"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header}>
                        <Column field="id" header="Codigo" sortable body={idBodyTemplate}></Column>
                        <Column field="escalafon" header="Escalafon" sortable body={escalafonBodyTemplate}></Column>
                        <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                        <Column field="email" header="Email" sortable body={emailBodyTemplate}></Column>
                        <Column field="telefono" header="Telefono" sortable body={telefonoBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    <Dialog visible={EntidadNewDialog} style={{ width: '700px' }} header="Datos de Usuario" modal className="p-fluid" footer={productDialogFooterNew} onHide={hideDialogNew}>
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="docIdentidad">Doc. de Identidad</label>
                            <div className="p-inputgroup">
                                <Button icon="pi pi-search" onClick={BuscarDNI} />
                                <InputText id="docIdentidad" name="docIdentidad" value={product.docIdentidad} onChange={(e) => onInputChange(e, 'docIdentidad')} required disabled className={classNames({ 'p-invalid': submitted && !product.docIdentidad })} />
                            </div>
                            {submitted && !product.docIdentidad && <small className="p-invalid">el documento de identidad es requerido.</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="escalafon">Nro de Escalafon</label>
                            <div className="p-inputgroup">
                                <InputText id="escalafon" name="escalafon" value={product.escalafon} onChange={(e) => onInputChange(e, 'escalafon')} required className={classNames({ 'p-invalid': submitted && !product.escalafon })} />
                            </div>
                            {submitted && !product.escalafon && <small className="p-invalid">el escalafon es requerido.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="nombre">Nombres</label>
                            <InputText id="nombre" name="nombre" value={product.nombre} onChange={(e) => onInputChange(e, 'nombre')} required disabled className={classNames({ 'p-invalid': submitted && !product.nombre })} />
                            {submitted && !product.nombre && <small className="p-invalid">el Nombre es requerido.</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="apellido">Apellidos</label>
                            <InputText id="apellido" name="apellido" value={product.apellido} onChange={(e) => onInputChange(e, 'apellido')} required disabled className={classNames({ 'p-invalid': submitted && !product.apellido })} />
                            {submitted && !product.apellido && <small className="p-invalid">el Apellido es requerido.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="regimen">Regimen</label>
                            <Dropdown placeholder={product.regimen} id="regimen" name="regimen" options={optionsRegimen} value={regimenId} onChange={handleChangeRegimen} appendTo={'self'} />
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-6">
                            <label htmlFor="email">Correo</label>
                            <InputText id="email" name="email" value={product.email} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !product.email })} />
                            {submitted && !product.email && <small className="p-invalid">el Correo es requerido.</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="nroTelefono">Telefono</label>
                            <InputText id="nroTelefono" name="nroTelefono" value={product.nroTelefono} onChange={(e) => onInputChange(e, 'nroTelefono')} required className={classNames({ 'p-invalid': submitted && !product.nroTelefono })} />
                            {submitted && !product.nroTelefono && <small className="p-invalid">el Telefono es requerido.</small>}
                        </div>
                    </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default Usuario;
