import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import Modal from 'react-modal';
//import './assets/scss/style.scss';

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import "primeflex/primeflex.css";

import 'primereact/resources/primereact.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';


import Menu from './pages/Menu';
import Login from './pages/Login';
import Perfil from './pages/Perfil';
import Usuario from './pages/Usuario';
import NotFoundPage from './pages/NotFoundPage';
import Lista from './pages/Lista';
import Elecciones from './pages/Elecciones';
import Reporte from './pages/Reporte';
import Estado from './pages/Estado';
// Modal.setAppElement('#root');

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Menu />} >
          <Route exact path="perfil" element={<Perfil />} />
          <Route exact path="usuario" element={<Usuario />} />
          <Route exact path="lista" element={<Lista />} />
          <Route exact path="elecciones" element={<Elecciones />} />
          <Route exact path='notfound' element={<NotFoundPage />} />
          <Route exact path="reporte" element={<Reporte />} />
          <Route exact path="estado" element={<Estado />} />
        </Route>
        <Route exact path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
