import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';
import classNames from 'classnames';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';

import { AppFooter } from '../AppFooter';
import { AppTopbar } from '../AppTopbar';
import { AppMenu } from '../AppMenu';

const Menu = () => {
    const cookies = new Cookies();
    let navigate = useNavigate();
    let menuClick = false;
    let mobileTopbarMenuClick = false;
    PrimeReact.ripple = true;
    const toast = useRef(null);
    //const token = localStorage.getItem('token');

    const copyTooltipRef = useRef();

    const admin = [{
        label: 'Administrador de Sistema',
        items: [
            { label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/',  },
            { label: 'Perfil', icon: 'pi pi-fw pi-id-card', to: 'perfil' },
            { label: 'Usuario', icon: 'pi pi-fw pi-users', to: 'usuario' },
        ]
    },
    {
        
        label: 'Elecciones',
        items: [
            { label: 'Registrar Lista', icon: 'pi pi-fw pi-book', to: 'lista' },
            { label: '𝗘𝗟𝗘𝗖𝗖𝗜𝗢𝗡𝗘𝗦 𝗦𝗨𝗕 𝗖𝗔𝗙𝗔𝗘 𝗟𝗔 𝗟𝗜𝗕𝗘𝗥𝗧𝗔𝗗 𝟮𝟬𝟮𝟱-𝟮𝟬𝟮𝟲', icon: 'pi pi-fw pi-users', to: 'elecciones' },
        ]
    },
    {
        
        label: 'Reportes',
        items: [
            { label: 'Estado Electoral', icon: 'pi pi-fw pi-chart-pie', to: 'estado' },
            { label: 'Reporte de Votacion', icon: 'pi pi-fw pi-file', to: 'reporte' },
        ]
    }]

    const user = [{
        label: 'Usuario',
        items: [
            { label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/' },
            { label: 'Perfil', icon: 'pi pi-fw pi-id-card', to: 'perfil' },
        ]
    },
    {
        label: 'Elecciones',
        items: [
            { label: '𝗘𝗟𝗘𝗖𝗖𝗜𝗢𝗡𝗘𝗦 𝗦𝗨𝗕 𝗖𝗔𝗙𝗔𝗘 𝗟𝗔 𝗟𝗜𝗕𝗘𝗥𝗧𝗔𝗗 𝟮𝟬𝟮𝟱-𝟮𝟬𝟮𝟲', icon: 'pi pi-fw pi-users', to: 'elecciones' },
        ]
    }]
    


    function Acceder() {
        if (cookies.get('role') === 'ADMIN') {
            return admin;
        } else {
            return user;
        }
    }

    const stylespj = {
        body: {
            height: '100%',
            width: '100%',
            //background: '#AFACAC',
            backgroundImage: "url(/logos/principal.png)",
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }
    }

    function Estilos() {
        return stylespj;
    }

    function Imagen() {
        return '/logos/UETICPP.png';
    }


    const menu = Acceder();
    const styles = Estilos();

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light');
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

    useEffect(() => {
        if (!cookies.get('id')) {
            navigate('/login');
        }
        document.documentElement.style.fontSize = 13 + 'px';
    }, [cookies, navigate]);


    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick} style={styles.body} >
            <Toast ref={toast} />
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" onClick={onSidebarClick} >
                {/* <div align="center"><img src={Imagen()} alt="hyper" height={80} className="mb-4" /></div> */}
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">
                <div className="container">
                    <Outlet />
                </div>
                <AppFooter />
            </div>
            {/* <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition> */}

        </div>
    );
}

export default Menu;