import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import classNames from 'classnames';
import { Chart } from 'primereact/chart';
import { Toast } from 'primereact/toast';
import { environment } from "./util/baseUrl";

export const Estado = () => {


    const cookies = new Cookies();
    let navigate = useNavigate();

    const baseUrl = environment.baseUrl + "voto/";
    const [data, setData] = useState(null);
    const [getTotalVotos, setTotalVotos] = useState(0);
    const toast = useRef(null);


    const peticionGet = async () => {
        try {
            const response = await axios.get(baseUrl + 'conteo');
            const rawData = response.data;

            const totalResponse = await axios.get(environment.baseUrl + 'usuario/total');
            const totalVotos = totalResponse.data;

            const votosEmitidos = rawData.reduce((sum, item) => sum + item.cantidadVotos, 0);
            const votosPendientes = totalVotos - votosEmitidos;

            const chartData = {
                labels: [
                    ...rawData.map(item => `Lista ${item.listaId}`),
                    'No votaron'
                ],
                datasets: [
                    {
                        data: [
                            ...rawData.map(item => item.cantidadVotos),
                            votosPendientes > 0 ? votosPendientes : 0
                        ],
                        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#BDBDBD'],
                        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9E9E9E']
                    }
                ]
            };

            setData(chartData);
            setTotalVotos(totalVotos);
        } catch (error) {
            console.error(error);
        }
    };





    useEffect(() => {
        if (cookies.get('role') === 'USER') {
            navigate('/');
        }
        peticionGet();
    }, []);


    return (
        <div className="grid p-fluid">
            <Toast ref={toast} />
            <div className="col-6 xl:col-6">
                <div className="card flex flex-column align-items-center">
                    <h5 className="text-left w-full">Estado Electoral</h5>
                    {data ? (
                        <Chart type="pie" data={data} />
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>
            <div className="col-6 xl:col-6">
                <div className="card">
                    <h5 className="text-left w-full">Estado Electoral</h5>
                    {data ? (
                        <ul className="list-none p-0">
                            {data.labels.slice(0, -1).map((label, index) => (
                                <li key={index} className="flex justify-content-between border-bottom-1 p-2">
                                    <span>{label}</span>
                                    <span>{data.datasets[0].data[index]} votos</span>
                                </li>
                            ))}
                            <li className="flex justify-content-between font-bold p-2">
                                <span>{data.labels[data.labels.length - 1]}</span>
                                <span>{data.datasets[0].data[data.datasets[0].data.length - 1]} no votaron</span>
                            </li>
                        </ul>
                    ) : (
                        <p>Loading detalles...</p>
                    )}
                </div>
            </div>
        </div >
    );
}

export default Estado;
