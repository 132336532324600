import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { environment } from "./util/baseUrl";
import { VotoEntity } from '../entity/VotoEntity';

export const Reporte = () => {

    let empty = VotoEntity;

    const cookies = new Cookies();
    let navigate = useNavigate();

    const baseUrl = environment.baseUrl + "voto/";
    const [data, setData] = useState(null);
    const [EntidadNewDialog, setEntidadNewDialog] = useState(false);

    const [product, setProduct] = useState(empty);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const peticionGet = async () => {
        await axios.get(baseUrl)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (cookies.get('role')==='USER') {
            navigate('/');
        }
        peticionGet();
    }, []);

    const exportCSV = () => {
        dt.current.exportCSV();
    }



    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-outlined p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.id}
            </>
        );
    }

    const fechaBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.fecha}
            </>
        );
    }

    const listacodigoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.lista.codigo}
            </>
        );
    }

    const listanombreBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.lista.nombre}
            </>
        );
    }

    const usuariodniBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.usuario.docIdentidad}
            </>
        );
    }

    const usuarionombreBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.usuario.nombre+' '+rowData.usuario.apellido}
            </>
        );
    }



    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Reporte de Votaciones</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                    <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Ver {first} a {last} de {totalRecords} Usuarios"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header}>
                        <Column field="id" header="id" sortable body={idBodyTemplate}></Column>
                        <Column field="fecha" header="Fecha" sortable body={fechaBodyTemplate}></Column>
                        <Column field="lista.codigo" header="Lista Codigo" sortable body={listacodigoBodyTemplate}></Column>
                        <Column field="lista.nombre" header="Lista Nombre" sortable body={listanombreBodyTemplate}></Column>
                        <Column field="usuario.docIdentidad" header="Lista DNI" sortable body={usuariodniBodyTemplate}></Column>
                        <Column field="usuario.apellido" header="Nombre de Usuario" sortable body={usuarionombreBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

export default Reporte;
