export const VotoEntity = {
    id: null,
    fecha: '',
    usuario: {
        id: null,
        docIdentidad: '',
        escalafon: '',
        nombre: '',
        apellido: '',
        role: '',

    },
    lista: {
        id: null,
        codigo: '',
        nombre: '',
    },

};
