import React from 'react';

export const AppFooter = (props) => {

    const styles = {
        body: {
            color: 'black',
        }
    }

    return (
        <div className="layout-footer">
            <img src="/logos/pjudicial.svg" alt="Logo" height="20" className="mr-2" />
            <span className="font-medium ml-2" style={styles.body}>Ing. Jheyson Gaitan - CSJLL</span>
        </div>
    );
}
