import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import classNames from 'classnames';

import { environment } from "./util/baseUrl";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { UsuarioEntity } from '../entity/UsuarioEntity';

const Perfil = () => {
    let empty = UsuarioEntity;

    let pass = {
        id: null,
        oldPassword: '',
        newPassword: ''
    }


    const [errors, setErrors] = useState({
        confirmPassword: '',
    });

    let navigate = useNavigate();
    const cookies = new Cookies();
    const [data, setData] = useState(empty);
    const [submitted, setSubmitted] = useState(false);
    const [EntidadNewDialog, setEntidadNewDialog] = useState(false);
    const [EntidadDialog, setEntidadDialog] = useState(false);
    const [product, setProduct] = useState(empty);
    const [passw, setPassw] = useState(pass);
    const [reniec, setReniec] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const toast = useRef(null);

    const formattedDateTime = (() => {
        const now = new Date();
    
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
        const year = now.getFullYear();
    
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${day}/${month}/${year}`;
        //return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    })();

    // Inicia valores para modificar Regimen 
    const [regimenId, setRegimenId] = useState(null);
    const optionsRegimen = [
        { label: '728', value: '728' },
        { label: '276', value: '276', },
        { label: '1057', value: '1057', }
    ]

    const handleChangeRegimen = (event) => {
        setRegimenId(event.value);
        product.regimen = event.value;
    };
    // Cierra valores para modificar Regimen 

    const peticionGet = async () => {
        await axios.get(environment.baseUrl + "usuario/" + cookies.get('id'))
            .then(response => {
                setData(response.data);
                setProduct(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionRENIEC = async () => {
        /*await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpnYWl0YW5yQG91dGxvb2suY29tIn0.hfkwOQKGq46Czu7RvqI1iSwgN8UGK02UEF4S9ZqXaCQ")*/
        await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpnYWl0YW5yQHBqLmdvYi5wZSJ9.-nX87AiyjDvfW2SeGAhWFnx0MDCiB8meK06aAAlVfJQ")
            .then(response => {
                setReniec(response.data);
                const nombre = response.data.nombres;
                const apellido = response.data.apellidoPaterno + " " + response.data.apellidoMaterno; // Ajusta la ruta de acceso correcta a la propiedad "nombres" según la respuesta del API
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    nombre: nombre,
                    apellido: apellido,
                }));
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionPutPassword = async () => {
        passw.id = cookies.get('id');
        await axios.post(environment.baseUrl + 'usuario/changepassword', passw)
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const peticionPut = async () => {
        product.ultSesion = formattedDateTime;
        await axios.put(environment.baseUrl + "usuario/", product)
            .then(() => {
                cookies.set('ultSesion', product.ultSesion, { path: '/' });
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
                setTimeout(() => {
                    navigate('/');
                }, 3000);
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const hideDialogNew = () => {
        setSubmitted(false);
        setEntidadNewDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEntidadDialog(false);
    }

    const openNew = () => {
        setPassw(pass);
        setSubmitted(false);
        setEntidadNewDialog(true);
    }

    const open = () => {
        setSubmitted(false);
        setEntidadDialog(true);
    }

    const saveProduct = () => {
        setSubmitted(true);
        if (passw.oldPassword.trim() && passw.newPassword.trim() && confirmPassword.trim()) {
            if (passw.newPassword === confirmPassword) {
                peticionPutPassword();
                setEntidadNewDialog(false);
                setPassw(pass);
                setConfirmPassword('');
            } else {
                setErrors({ ...errors, confirmPassword: 'Las contraseñas no coinciden.' });
            }
            setEntidadNewDialog(false);
            setPassw(pass);
        } else if (product.email && product.nroTelefono && product.regimen) {
            peticionPut();
            setEntidadDialog(false);
        }
    }

    const BuscarDNI = () => {
        setSubmitted(true);
        if (product.docIdentidad.trim()) {
            peticionRENIEC();
            //setProduct(empty);
        }
    }
    const onInputChangePass = (e, name) => {
        const val = (e.target && e.target.value) || '';
        if (name === 'newPassword' || name === 'confirmPassword') {
            if (name === 'newPassword') {
                setPassw({ ...passw, [name]: val });
            } else {
                setConfirmPassword(val);
            }
            if (passw.newPassword !== val) {
                setErrors({ ...errors, confirmPassword: 'Las contraseñas no coinciden.' });
            } else {
                setErrors({ ...errors, confirmPassword: '' });
            }
        } else {
            setPassw({ ...pass, [name]: val });
        }
        let _pass = { ...passw };
        _pass[`${name}`] = val;
        setPassw(_pass);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        setProduct(_product);

        if (name === 'fecha') {
            _product[name] = val.toString();
        }
        else {
            _product[name] = val;
        }
        _product[`${name}`] = val;
    }

    const productDialogFooterNew = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogNew} />
            <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    useEffect(() => {
        peticionGet();
    }, []);

    return (
        <div className="grid">
            <Toast ref={toast} />
            <div className="col-12">
                <div className="card">
                    <div className="surface-section">
                        <div className="font-medium text-3xl text-900 mb-3">Información de Usuario</div>
                        <div className="text-500 mb-5">Datos personales.</div>
                        <ul className="list-none p-0 m-0">
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Nombres: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.nombre}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Apellidos: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.apellido}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">N° Documento: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.docIdentidad}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Escalafón: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.escalafon}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Régimen: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.regimen}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Numero de Telefono: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.nroTelefono}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Correo Electronico: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.email}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Permisos: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> {cookies.get('role')}</div>
                            </li>
                        </ul>
                    </div>
                    <br />
                    <div className="my-2">
                        <Button label="Actualizar Datos" icon="pi pi-pencil" className="p-button-outlined p-button-success mr-2" onClick={open} />
                        <Button label="Cambiar Contraseña" icon="pi pi-lock-open" className="p-button-outlined p-button-success mr-2" onClick={openNew} />
                    </div>
                </div>
                <Dialog visible={EntidadNewDialog} style={{ width: '350px' }} header="Cambiar Contraseña" modal className="p-fluid" footer={productDialogFooterNew} onHide={hideDialogNew}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText placeholder="Antiguo Password" type="password" id="oldPassword" name="oldPassword" value={passw.oldPassword} onChange={(e) => onInputChangePass(e, 'oldPassword')} required autoFocus className={classNames({ 'p-invalid': submitted && !passw.oldPassword })} />
                        </div>
                        {submitted && !passw.oldPassword && <small className="p-invalid">el old Password es requerido.</small>}
                    </div>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText placeholder="Nuevo Password" type="password" id="newPassword" name="newPassword" value={passw.newPassword} onChange={(e) => onInputChangePass(e, 'newPassword')} required className={classNames({ 'p-invalid': submitted && !passw.newPassword })} />
                        </div>
                        {submitted && !passw.newPassword && <small className="p-invalid">el new Password es requerido.</small>}
                    </div>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText placeholder="Confirmar Password" type="password" id="confirmPassword" name="confirmPassword" value={confirmPassword} onChange={(e) => onInputChangePass(e, 'confirmPassword')} required className={classNames({ 'p-invalid': submitted && !confirmPassword })} />
                        </div>
                        {submitted && !passw.newPassword && <small className="p-invalid">La confirmacion de contraseña es requerida.</small>}
                        {submitted && errors.confirmPassword && <small className="p-invalid">{errors.confirmPassword}</small>}
                    </div>
                </Dialog>
                <Dialog visible={EntidadDialog} style={{ width: '900px' }} header="Datos de Usuario" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="docIdentidad">Doc. de Identidad</label>
                            <div className="p-inputgroup">
                                <Button icon="pi pi-search" onClick={BuscarDNI} />
                                <InputText id="docIdentidad" name="docIdentidad" value={product.docIdentidad} onChange={(e) => onInputChange(e, 'docIdentidad')} required disabled className={classNames({ 'p-invalid': submitted && !product.docIdentidad })} />
                            </div>
                            {submitted && !product.docIdentidad && <small className="p-invalid">el documento de identidad es requerido.</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="escalafon">Nro de Escalafon</label>
                            <div className="p-inputgroup">
                                <InputText id="escalafon" name="escalafon" value={product.escalafon} onChange={(e) => onInputChange(e, 'escalafon')} required className={classNames({ 'p-invalid': submitted && !product.escalafon })} />
                            </div>
                            {submitted && !product.escalafon && <small className="p-invalid">el escalafon es requerido.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="nombre">Nombres</label>
                            <InputText id="nombre" name="nombre" value={product.nombre} onChange={(e) => onInputChange(e, 'nombre')} required className={classNames({ 'p-invalid': submitted && !product.nombre })} />
                            {submitted && !product.nombre && <small className="p-invalid">el Nombre es requerido.</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="apellido">Apellidos</label>
                            <InputText id="apellido" name="apellido" value={product.apellido} onChange={(e) => onInputChange(e, 'apellido')} required className={classNames({ 'p-invalid': submitted && !product.apellido })} />
                            {submitted && !product.apellido && <small className="p-invalid">el Apellido es requerido.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="regimen">Regimen</label>
                            <Dropdown placeholder={product.regimen} id="regimen" name="regimen" options={optionsRegimen} value={regimenId} onChange={handleChangeRegimen} appendTo={'self'} />
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-6">
                            <label htmlFor="email">Correo</label>
                            <InputText id="email" name="email" value={product.email} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !product.email })} />
                            {submitted && !product.email && <small className="p-invalid">el Correo es requerido.</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="nroTelefono">Telefono</label>
                            <InputText id="nroTelefono" name="nroTelefono" value={product.nroTelefono} onChange={(e) => onInputChange(e, 'nroTelefono')} required className={classNames({ 'p-invalid': submitted && !product.nroTelefono })} />
                            {submitted && !product.nroTelefono && <small className="p-invalid">el Telefono es requerido.</small>}
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default Perfil;