import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import Cookies from 'universal-cookie';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Rating } from 'primereact/rating';
import { Toast } from 'primereact/toast';

import { environment } from "./util/baseUrl";
import { useNavigate } from 'react-router-dom';

import { VotoEntity } from '../entity/VotoEntity';
import { UsuarioEntity } from '../entity/UsuarioEntity';

const Elecciones = () => {

    let empty = VotoEntity;
    const [product, setProduct] = useState(empty);

    let usuario = UsuarioEntity;
    const [getusuario, setUsuario] = useState(usuario);

    const cookies = new Cookies();
    const navigate = useNavigate();
    const baseUrl = environment.baseUrl + "lista/";
    const [data, setData] = useState(null);
    const [dataViewValue, setDataViewValue] = useState(null);
    const [layout, setLayout] = useState('grid');
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const toast = useRef(null);

    //Incio de Bloqueo de pantalla
    const [mostrarDialogo, setMostrarDialogo] = useState(false);
    const [datosDialogo, setDatosDialogo] = useState(false);
    //Fin de bloqueo de pantalla

    //Incio de Bloqueo de boton
    const [isDisabled, setIsDisabled] = useState(false);
    const handleClick = (id) => {
        saveProduct(id);
        setIsDisabled(true);
    };
    const [selectedItemId, setSelectedItemId] = useState(null);
    //Fin de Bloqueo de boton

    const [confirmVote, setConfirmVote] = useState(false);

    // Dialogo de confirmación
    const showConfirmDialog = (id) => {
        setConfirmVote(true); // Mostrar el diálogo de confirmación
        setSelectedItemId(id); // Almacena el ID para la acción de votación
    };

    const confirmVoteHandler = () => {
        setIsDisabled(true);
        saveProduct(selectedItemId);
        setConfirmVote(false); // Cierra el diálogo de confirmación
    };

    const cancelVoteHandler = () => {
        setIsDisabled(false);
        setConfirmVote(false); // Cierra el diálogo de confirmación sin realizar la acción
    };

    const formattedDateTime = (() => {
        const now = new Date();

        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
        const year = now.getFullYear();

        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        //return `${day}/${month}/${year}`;
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    })();

    const peticionGet = async () => {
        await axios.get(baseUrl)
            .then(response => {
                setDataViewValue(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionGetusuario = async () => {
        await axios.get(environment.baseUrl + "usuario/" + cookies.get('id'))
            .then(response => {
                setUsuario(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionPost = async () => {
        try {
            delete product.id;
            await axios.post(environment.baseUrl + "voto/", product);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Registro Ingresado Correctamente', life: 3000, });

            console.log(getusuario);
            getusuario.estado = true;
            getusuario.ultSesion = formattedDateTime;

            cookies.set('estado', getusuario.estado, { path: '/' });
            console.log(getusuario);

            await axios.put(environment.baseUrl + "usuario/", getusuario);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Usuario Actualizado Correctamente', life: 3000, });

            setTimeout(() => {
                navigate('/');
            }, 5000);

        } catch (error) {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Ocurrió un problema en el proceso', life: 5000, });
        }
    };


    const stringToDate = (dateString) => {
        if (!dateString) return null;
        if (dateString instanceof Date) {
            return dateString;
        }
        if (typeof dateString === 'number') {
            return new Date(dateString);
        }
        if (typeof dateString === 'string' && dateString.includes('/')) {
            const [day, month, year] = dateString.split('/').map(Number);
            return new Date(year, month - 1, day);
        }
        return null;
    };

    // Función para validar la fecha de la cookie
    const validarUltSesion = () => {
        const cookieValue = cookies.get('ultSesion');
        if (cookieValue) {
            const fechaSesion = stringToDate(cookieValue);
            const diciembre2024 = new Date(2024, 11, 1);
            return fechaSesion < diciembre2024;
        }
        return true;
    };

    useEffect(() => {
        if (cookies.get('estado') === 'true') {
            setMostrarDialogo(true);
            setTimeout(() => {
                navigate('/');
            }, 4000);
        }

        if (validarUltSesion()) {
            setDatosDialogo(true);
            setTimeout(() => {
                navigate('/perfil');
            }, 4000);
        } else {
            //console.log('La fecha de última sesión es válida.');
        }

        peticionGet();
        peticionGetusuario();
    }, []);


    const dataViewHeader = (
        <div className="flex flex-column md:flex-row md:justify-content-between gap-2">
            <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
        </div>
    );

    const saveProduct = (dato) => {
        product.usuario.id = Number(cookies.get('id'));
        product.usuario.role = cookies.get('role');
        product.lista.id = dato;
        product.fecha = formattedDateTime;
        console.log(product);
        if (product.usuario.id && product.lista.id && product.fecha) {
            peticionPost();
        }
    }

    const dataviewListItem = (data) => {
        return (
            <div className="col-12">
                <div className="flex flex-column md:flex-row align-items-center p-3 w-full">
                    <div className="text-4xl font-bold">{data.codigo}</div>
                    <div className="flex-1 flex flex-column align-items-center text-center md:text-left">
                        <div className="font-bold text-2xl">{data.nombre}</div>
                        <div className="mb-2">{data.titular}</div>
                        <Rating value="5" readOnly cancel={false} className="mb-2"></Rating>
                    </div>
                    <div className="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
                        <Button label="Votar por esta lista" icon="pi pi-check-square" disabled={isDisabled} onClick={() => showConfirmDialog(data.id)} />
                    </div>
                </div>
            </div>
        );
    };

    const dataviewGridItem = (data) => {
        return (
            <div className="col-12 lg:col-4">
                <div className="card m-3 border-1 surface-border" >
                    <div className="flex flex-column align-items-center text-center mb-3">
                        <div className="text-4xl font-bold">{data.codigo}</div>
                        <div className="text-2xl font-bold">{data.nombre}</div>
                        <div className="mb-3">{data.titular}</div>
                        <Rating value="5" readOnly cancel={false} />
                    </div>
                    <div className="flex flex-column align-items-center justify-content-between">
                        <Button label="Votar por esta lista" icon="pi pi-check-square" disabled={isDisabled} onClick={() => showConfirmDialog(data.id)} />
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }
        if (layout === 'list') {
            return dataviewListItem(data);
        } else if (layout === 'grid') {
            return dataviewGridItem(data);
        }
    };

    return (
        <div className="grid list-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <DataView value={dataViewValue} layout={layout} paginator rows={9} sortOrder={sortOrder} sortField={sortField} itemTemplate={itemTemplate} header={dataViewHeader}></DataView>
                </div>
            </div>
            <Dialog header="Confirmar voto" visible={confirmVote} style={{ width: '30vw' }} footer={<>
                <Button label="Cancelar" icon="pi pi-times" onClick={cancelVoteHandler} className="p-button-text" />
                <Button label="Confirmar" icon="pi pi-check" onClick={confirmVoteHandler} autoFocus />
            </>} onHide={() => setConfirmVote(false)} closable={false}>
                <p>¿Estás seguro de que deseas votar?</p>
            </Dialog>
            <Dialog header="Acceso Restringido" visible={mostrarDialogo} modal closable={false} onHide={() => { }} style={{ width: '30vw', textAlign: 'center' }}>
                <p>Tu voto ya fue registrado.</p>
            </Dialog>
            <Dialog header="Acceso Restringido" visible={datosDialogo} modal closable={false} onHide={() => { }} style={{ width: '30vw', textAlign: 'center' }}>
                <p>Tu voto está bloqueado debido a que aun no has completado tu cambio de datos, actualiza tus datos.</p>
            </Dialog>
        </div>
    );
};

export default Elecciones;